import React, { useState, useRef } from 'react'
import { Checkbox, ProgressIndicator } from '@veneer/core'

import AssetsProviderFactory from '../../assets/AssetsProviderFactory'

import { Container } from './styles'

const YourReactComponentHere = ({ language = 'en', country = 'us' }) => {
  const assetsProvider = useRef(AssetsProviderFactory.create(language, country))
  const [checkboxValue, setCheckboxValue] = useState(false)

  const getText = (subkey: string) => {
    const assetKey = `application.${subkey}`
    return assetsProvider.current.getText(assetKey)
  }

  const toggleCheckbox = () => {
    setCheckboxValue((checkboxState) => !checkboxState)
  }

  return (
    <Container>
      <h6>{getText('header')}</h6>
      <div>
        <Checkbox
          checked={checkboxValue}
          id="myCheckbox"
          label={getText('checkbox.label')}
          name="myCheckbox"
          onChange={toggleCheckbox}
        />
      </div>
      <ProgressIndicator />
    </Container>
  )
}

export default YourReactComponentHere
