/* eslint-disable camelcase */
import bg_BG from './bg_BG.json'
import cs_CZ from './cs_CZ.json'
import el_GR from './el_GR.json'
import en_AU from './en_AU.json'
import en_IL from './en_IL.json'
import en_NZ from './en_NZ.json'
import en_US from './en_US.json'
import et_EE from './et_EE.json'
import hr_HR from './hr_HR.json'
import hu_HU from './hu_HU.json'
import lt_LT from './lt_LT.json'
import lv_LV from './lv_LV.json'
import pl_PL from './pl_PL.json'
import ro_RO from './ro_RO.json'
import sk_SK from './sk_SK.json'

export default {
  bg_BG,
  cs_CZ,
  el_GR,
  en_AU,
  en_IL,
  en_NZ,
  en_US,
  et_EE,
  hr_HR,
  hu_HU,
  lt_LT,
  lv_LV,
  pl_PL,
  ro_RO,
  sk_SK
}
